.sc-list {
	&-messages {
		@extend %reset-list;
		border-bottom: 1px solid $border-color-light;
		overflow: hidden;
		> li {
			background: $white;
			min-height: 68px;
			transition: background-color 140ms ease;
			cursor: pointer;
			margin-top: -1px;
			border-top: 1px solid $border-color-light;
			+ li {
			}
			&.sc-item-checked {
				background: $highlight-bg-alt;
			}
			.sc-message-card:not(.sc-message-expanded) {
				transition: all 140ms ease;
			}
			&:hover {
				border-color: transparent;
				.sc-message-card:not(.sc-message-expanded) {
					z-index: 10;
					position: relative;
					@include elevation(6);
					.sc-message {
						&-date {
							display: none;
						}
						&-actions {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}
	}
}
.sc-message {
		&-card {
			padding: 12px 24px;
			box-sizing: border-box;
			display: flex;
			flex-flow: column;
			width: 100%;
			overflow-x: hidden;
		}
		&-title {
			font-weight: 400;
			@include font-size(16px);
			@include sc-font-primary;
			margin: 0;
			padding-right: 24px;
		}
		&-date {
			@include font-size(14px);
			color: $text-color-secondary;
		}
		&-close {
			@include font-size(24px);
			margin: 0 0 0 24px;
			@include hover(true);
		}
		&-actions {
			display: none;
			.mdi {
				height: 32px;
				width: 32px;
				text-align: center;
				text-decoration: none;
				color: $text-color-secondary;
				&:before {
					line-height: 32px;
				}
				+ .mdi {
					margin-left: 8px;
				}
				&.uk-open:after,
				&:hover:after {
					transform: scale3d(1.2,1.2,1.2);
				}
			}
		}
		&-expanded {
			//position: absolute;
			//background: $white;
			//z-index: $z-index-overlay + 50;
			//padding: 24px;
			//width: 100%;
			//align-self: flex-start;
			//align-items: normal;
			//cursor: default;
			//margin-top: -12px;
			//overflow: hidden;
			//@include edge-fix() {
			//	@include respond-below('medium') {
			//		.sc-message-head {
			//			flex-direction: column !important;
			//			justify-content: left;
			//			align-items: flex-start;
			//			.uk-text-truncate {
			//				width: auto;
			//				overflow: visible;
			//				white-space: normal;
			//			}
			//			.sc-message-title {
			//				line-height: 1.5;
			//			}
			//			.sc-message-date {
			//				padding-top: 8px;
			//			}
			//		}
			//	}
			//	max-height: calc(100% - 48px);
			//}
			#sc-message-single {
				background: $white;
				position: absolute;
				left: 0;
				right: 0;
				z-index: $z-index-overlay + 100;
				display: none;
				.sc-message-card {
					height: 100%;
					overflow: hidden;
					padding: 24px;
				}
				@include respond-above('medium') {
					overflow: hidden;
				}
				@include respond-below('medium') {
					&.sc-js-expanded {
						&:before {
							position: absolute;
							bottom: -80px;
							left: 0;
							right: 0;
							background: $white;
							content: '';
							height: 80px;
							z-index: $z-index-overlay + 200;
						}
					}
				};
			}
		}
		&-head {
			align-self: flex-start;
			width: 100%;
		}
		&-content {
			margin-top: $spacing-default;
			overflow-y: auto;
			flex: 1;
			-webkit-overflow-scrolling: touch;
		}
		&-attachments {
			list-style: none;
			margin: -8px 0 0 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			li {
				padding: 0;
				margin: 8px 8px 0 0;
				display: inline-flex;
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				.sc-attachment {
					padding: 8px 8px 8px 12px;
					background-color: material-color('grey','100');
					display: flex;
					position: relative;
					font-weight: 500;
					@include font-size(14px);
					border-radius: 4px;
					align-items: center;
					a {
						text-decoration: none;
						span {
							color: $text-color-secondary;
						}
					}
					&-remove {
						@include font-size(20px);
						width: 30px;
						height: 30px;
						text-align: center;
						margin-left: 8px;
						&:before {
							line-height: 30px;
							padding: 0;
						}
					}
				}
			}
		}
		&-footer {
			box-sizing: border-box;
			align-self: flex-end;
			width: 100%;
			flex-shrink: 0;
		}
		&-reply {
			border-radius: $border-radius;
			box-sizing: border-box;
			width: 100%;
			padding: 12px;
			@include elevation(2);
			margin-top: 24px;
		}
		&-unreaded {
			background: $highlight-bg-light;
			.sc-message {
				&-title {
					font-weight: 500;
				}
			}
		}
	}
.sc-js-expand-message {
	> li {
		cursor: pointer;
	}
}
.sc-message-back {
	display: none;
}
.sc-checkbox-wrapper {
	margin-right: $spacing-medium;
}
#sc-page-wrapper {
	&.sc-message-single {
		.sc-message-back {
			display: block;
		}
		.sc-checkbox-wrapper {
			display: none;
		}
		.sc-top-bar-content {
			padding-left: 16px;
		}
		@include respond-above('medium') {
			.sc-message-expanded {
				#sc-message-single {
					.sc-message-card {
						height: calc(100% - 112px)
					}
					.sc-message-footer {
						flex: 1;
					}
				}
			}
		}
		@include respond-below('medium') {
			.sc-top-bar-content {
				padding-right: 16px;
			}
			.sc-message-back {
				flex: 1;
				min-width: 1px;
			}
			.sc-message-expanded {
				#sc-message-single {
					.sc-message-head {
						flex-direction: column !important;
						justify-content: left;
						align-items: flex-start;
						.uk-text-truncate {
							width: auto;
							overflow: visible;
							white-space: normal;
						}
						.sc-message-title {
							line-height: 1.5;
							@include font-size(18px);
						}
						.sc-message-date {
							padding-top: 8px;
						}
					}
				}
			}
		}
	}
}