[data-sc-input] {
	border-width: 0 0 1px 0;
	border-color: $border-color-light;
	border-radius: 4px 4px 0 0;
	&:focus {
		border-color: $border-color-hover;
	}
}
.sc-input {
	&-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		> label {
			color: $text-color-secondary;
			position: absolute;
			top: 16px;
			left: 8px;
			padding-left: 0;
			right: 0;
			pointer-events: none;
			transition: all 140ms $easing;
			@include font-size(14px);
			&.uk-label-large {
				@include font-size(18px);
				top: 18px;
				left: 12px;
				+ [data-sc-input] {
					height: 64px;
					padding: 18px 12px 4px;
				}
			}
			&.uk-label-small {
				@include font-size(12px);
				top: 12px;
				left: 8px;
				+ [data-sc-input] {
					height: 40px;
					padding: 16px 8px 2px;
				}

			}
			+ textarea[data-sc-input] {
				margin-top: 22px;
				padding-top: 0;
			}
			+ input[data-sc-input] {
				padding: 18px 8px 4px;
				height: 52px;
			}
		}
		.uk-form-icon {
			margin-top: -1px;
			height: 40px;
		}
		&-danger {
			> label {
				color: $danger-color;
			}
			.sc-input-bar {
				&:before,
				&:after {
					background: $danger-color;
				}
			}
			.uk-form-icon {
				color: $danger-color;
			}
		}
		&-success {
			.sc-input-bar {
				&:before,
				&:after {
					background: $success-color;
				}
			}
			.uk-form-icon {
				color: $success-color;
			}
		}
		&-outline {
			label {
				background: $white;
				display: inline-block;
				right: auto;
				left: 12px;
				top: 10px;
				border-radius: 2px;
				+ input[data-sc-input] {
					height: 40px;
					padding: 10px 12px 6px;
				}
				+ textarea[data-sc-input] {
					height: auto;
					margin-top: 0;
					padding-top: 8px;
				}
			}
			.uk-form-icon {
				left: 0;
				margin-top: 0;
				&-flip {
					right: 0;
					left: auto;
				}
			}
			&.sc-input-wrapper {
				&-danger {
					label {
						color: $danger-color
					}
				}
				//&-success {
				//    label {
				//        color: $success-color
				//    }
				//}
			}
		}
	}
	&-filled,
	&-focus {
		> label {
			top: 4px;
			@include font-size(12px);
			&.uk-label-large {
				@include font-size(14px);
				top: 6px;
			}
			&.uk-label-small {
				@include font-size(10px);
				top: 4px;
			}
		}
		&.sc-input-wrapper-count {
			.text-count-wrapper {
				opacity: 1;
			}
		}
		&.sc-input-wrapper-outline {
			> label {
				top: -9px;
				left: 8px;
				right: auto;
				padding: 0 4px;
			}
		}
	}
	&-focus {
		.sc-input-bar:before,
		.sc-input-bar:after {
			width: 50%;
		}
	}
	&-match-field {
		display: flex;
		align-items: center;
		height: 52px;
	}
}
.sc-input-outline,
.sc-textarea-outline {
	border-radius: 4px;
	border-width: 1px;
	padding: 8px 12px 7px;
	&:focus {
		border-color: $input-on-color;
	}
}
.sc-input-outline {
	height: 40px;
}

.sc-input-bar {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		width: 0;
		height: 2px;
		background: $input-on-color;
		transition: width 140ms $easing;
	}
	&:before {
		left: 50%
	}
	&:after {
		right: 50%
	}
}
.uk-form-width {
	&-xsmall {
		+ .sc-input-bar {
			width: 50px;
		}
	}
	&-small {
		+ .sc-input-bar {
			width: 130px;
		}
	}
	&-medium {
		+ .sc-input-bar {
			width: 200px;
		}
	}
	&-large {
		+ .sc-input-bar {
			width: 500px;
		}
	}
}

.sc-switch {
	&-label {
		position: relative;
		display: inline-block;
		min-width: 120px;
		cursor: pointer;
		text-align: left;
		padding: 4px 0 4px 44px;
		user-select: none;
		&:before,
		&:after {
			content: "";
			position: absolute;
			margin: 0;
			outline: 0;
			top: 50%;
			transform: translate(0, -50%);
			transition: all 140ms $easing;
		}
		&:before {
			left: 1px;
			width: 34px;
			height: 14px;
			background-color: #9e9e9e;
			border-radius: 8px;
		}
		&:after {
			left: 0;
			width: 20px;
			height: 20px;
			background-color: material-color('grey','200');
			border-radius: 50%;
			border: 10px solid $white;
			background: $white;
			box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
			box-sizing: border-box;
		}
	}
	&-toggle-on {
		display: none;
	}
	&-toggle-off {
		display: inline-block;
	}
	&-input {
		display: none;
		&:checked {
			+ .sc-switch-label {
				&:before {
					background-color: $input-on-color-light;
				}
				&:after {
					background-color: $input-on-color;
					border-color: $input-on-color;
					transform: translate(80%, -50%);
				}
				.sc-switch-toggle-on {
					display: inline-block;
				}
				.sc-switch-toggle-off {
					display: none;
				}

			}
		}
	}
}

.sc-form-error-block {
	color: $danger-color;
	@include font-size(12px);
	padding: 8px 0 0 8px;
	display: inline-block;
}
.sc-form-errors-list {
	color: $danger-color;
	@include font-size(14px);
	padding: 8px 0 4px 8px;
	display: none;
	&.filled {
		display: block;
	}
}

.cc-validate-wrapper {
	position: relative;
	> .cc-icon {
		position: absolute;
		left: 6px;
		top: 29px;
		width: 52px;
		height: 30px;
		background: material-color('grey','200');
		border-radius: 4px;
		overflow: hidden;
		display: block;
		> img {
			margin: -2px 0 0 0;
		}
	}
	> .uk-input {
		padding-left: 72px;
	}
}
