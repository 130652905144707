/*
*	Scutum Admin
*	author: tzd
*
*	Content:
*	1. colors; variables/mixins; common styles
*	2. UIkit custom styles
*	3. partials (header,sidebars,top bar)
*	4. custom components
*	5. common plugins
*	6. custom pages
*	7. themes
*	8. print styles
*
*/

/* 1. variables/mixins ======================== */
	@import "common/md_colors";
	@import "common/variables_mixins";
	@import "common/common";

/* 2. UIkit ========================================= */
	@import "common/uikit_custom";

/* 3. partials ====================================== */
	@import "partials/top_bar";
	@import "partials/header";
	@import "partials/sidebar";
	@import "partials/toolbar";
	@import "partials/footer";

/* 4. components ==================================== */
	@import "components/colors";
	@import "components/cards";
	@import "components/colorpicker";
	@import "components/forms";
	@import "components/buttons";
	@import "components/icons";
	@import "components/maps";
	@import "components/offcanvas";
	@import "components/progress";
	@import "components/timeline";
	@import "components/waves";
	@import "components/top_menu";

/* 5. common plugins ================================ */
	@import "components/icheck";
	@import "components/highlight_js";
	@import "components/charts";

/* 6. custom pages ================================== */
	@import "pages/chat";
	@import "pages/invoice";
	@import "pages/mailbox";
	@import "pages/task_board";

/* 7. themes ======================================= */
	//@import "themes";

/* 8. print ======================================== */
	//@import "print";
