.sc-timeline {
	position: relative;
	min-height: 32px;
	padding: 16px 0;
	&:after {
		position: absolute;
		top: 0;
		left: 84px;
		content: '';
		background: material-color('grey','300');
		width: 4px;
		height: 100%;
		border-radius: $border-radius;
		z-index: 10;
		@include respond-below(medium) {
			left: 20px !important;
			margin: 0;
		}
	}
	&-item {
		display: flex;
		align-content: center;
		align-items: flex-start;
		z-index: 20;
		position: relative;
		+ .sc-timeline-item {
			margin-top: 16px;
		}
		@include respond-below(medium) {
			display: block;
			padding-left: 52px;
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
	}
	&-icon {
		width: 36px;
		height: 36px;
		margin-right: 16px;
		margin-top: 5px;
		text-align: center;
		background: $white;
		border-radius: $border-radius * 2;
		border: 4px solid $white;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond-below(medium) {
			position: absolute;
			top: 0;
			left: 0;
			margin: 0 !important;
		}
	}
	&-date {
		@include font-size(14px);
		width: 48px;
		margin-right: 16px;
		height: 53px;
		flex-shrink: 0;
		font-weight: 500;
		color: $text-color-secondary;
		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		line-height: 18px;
		text-align: center;
		@include respond-below(medium) {
			width: 100%;
			text-align: left;
			display: block;
			padding: 14px 0 0 0;
			margin: 0 0 16px;
			margin-left: 16px !important;
			height: 36px !important;
			box-sizing: border-box;
		}
	}
	&-body {
		align-self: flex-start;
		padding: 16px;
		border-radius: $border-radius;
		flex: 1;
		max-width: 100%;
		overflow: hidden;
		background: $white;
		&-border {
			border: 1px solid $border-color;
		}
		@include respond-below(medium) {
		}
	}
	&-content {
		@include font-size(14px);
	}
	&-header {
		@include font-size(16px);
		margin: 0;
	}
	&-meta {
		@include font-size(14px);
		color: $text-color-secondary;
	}
	hr {
		margin-bottom: 16px;
	}
	* + hr {
		margin-top: 12px;
	}
	&-center {
		&:after {
			top: 0;
			left: 50%;
			margin-left: -2px;
		}
		.sc-timeline {
			&-item {
				margin-left: calc(50% - 86px);
				&:nth-child(odd) {
					flex-direction: row-reverse;
					margin-right: calc(50% - 86px);
					margin-left: 0;
					.sc-timeline-date,
					.sc-timeline-icon {
						margin-left: 16px;
						margin-right: 0;
					}
				}
			}
		}
	}
	&-right {
		&:after {
			top: 0;
			right: 84px;
			left: auto;
			@include respond-below(medium) {
				right: 20px !important;
				left: auto !important;
			}
		}
		.sc-timeline {
			&-item {
				flex-direction: row-reverse;
				.sc-timeline-date,
				.sc-timeline-icon {
					margin-left: 16px;
					margin-right: 0;
				}
				@include respond-below(medium) {
					padding-left: 0;
					padding-right: 52px;
				}
			}
			&-icon {
				@include respond-below(medium) {
					left: auto;
					right: 0;
				}
			}
			&-date {
				@include respond-below(medium) {
					text-align: right;
					margin-left: 0 !important;
					margin-right: 0 !important;
					padding-right: 16px;
				}
			}
		}
	}
}
