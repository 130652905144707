// vector maps
.sc-vmap {
	width: 100%;
	height: 320px;
}
// google map
.sc-gmap {
	width: 100%;
	height: 360px;
}
.gm-style img {
	box-sizing: initial;
}

// infowindow
.sc-iw-title {
	font-weight: 500;
	@include font-size(14px);
	padding: 16px 32px 8px 16px;
	text-transform: uppercase;
}
.sc-iw-body {
	padding: 16px 16px 0;
	margin-bottom: 16px;
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	line-height: 1.5;
}
.sc-iw-footer {
	padding: 0 16px 16px;
}
.sc-iw-close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 8px;
	.mdi {
		color: $text-color-secondary;
		&:before {
			@include font-size(20px);
		}
	}
	&:hover {
		.mdi {
			color: $text-color-primary;
		}
	}
}
