.sc {
	&-progress {
		position: relative;
		max-width: 100%;
		box-shadow: none;
		background: rgba(0,0,0,.1);
		border-radius: 6px;
		display: flex;
		&-label {
			position: absolute;
			bottom: 100%;
			@include font-size(12px);
			width: 100%;
			color: $text-color-secondary;
			font-weight: 500;
			margin-bottom: 4px;
			text-align: center;
			&-enabled {
				margin-top: 28px;
			}
		}
		&-bar {
			position: relative;
			height: 4px;
			min-width: 12px;
			background: material-color('grey','400');
			transition: width 360ms $easing, background-color 140ms $easing;
			color: $white;
			box-sizing: border-box;
			text-align: center;
			overflow: hidden;
		}
		&-medium {
			.sc-progress-bar {
				height: 8px;
				@include font-size(10px);
				line-height: 8px;
			}
		}
		&-large {
			.sc-progress-bar {
				height: 16px;
				@include font-size(11px);
				line-height: 17px;
			}
		}
		&-medium,
		&-large {
			border-radius: $border-radius;
			.sc-progress-bar {
				border-radius: $border-radius;
			}
			&.sc-progress-group {
				.sc-progress-bar {
					border-radius: $border-radius 0 0 $border-radius;
					+ .sc-progress-bar {
						border-radius: 0;
					}
					&:last-child {
						border-radius: 0 $border-radius $border-radius 0;
					}
				}
			}
		}
		&-indeterminate {
			overflow: hidden;
			.sc-progress-bar {
				width: 100%;
				animation: indeterminate 2800ms infinite linear;
			}
		}
	}
	&-progress-circular {
		border: 4px solid rgba(0, 0, 0, .12);
		border-top: 4px solid $theme-color;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		animation: circular-animation 800ms linear infinite;
		display: inline-block;
		&-small {
			width: 12px;
			height: 12px;
			border-width: 2px;
		}
		&-large {
			width: 48px;
			height: 48px;
			border-width: 8px;
		}
		&-secondary {
			border-top-color: $theme-complementary-color;
		}
		&-light {
			border-color: rgba(0,0,0,.2);
			border-top-color: rgba(255,255,255,.9)
		}
	}
}
@keyframes circular-animation {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes indeterminate {
	0% {
		transform: translateX(-100%) scaleX(0.2);
	}
	20% {
		transform: translateX(-40%) scaleX(0.2);
	}
	30% {
		transform: translateX(0%) scaleX(0.5);
	}
	55% {
		transform: translateX(100%) scaleX(0.7);
	}
	55.99% {
		transform: scaleX(0);
	}
	56% {
		transform: translateX(-100%) scaleX(0);
	}
	56.99% {
		transform: translateX(-100%) scaleX(0.6);
	}
	75% {
		transform: translateX(-5%) scaleX(0.6);
	}
	85% {
		transform: translateX(30%) scaleX(0.3);
	}
	98% {
		transform: translateX(100%) scaleX(0.2);
	}
	99.99% {
		transform: scaleX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}