#sc-header {
	z-index: $z-index-header;
	height: $header-height;
	transition: box-shadow 280ms $easing;
	position: relative;
	.sc-brand {
		display: inline-block;
		> a {
			line-height: $header-height - 2;
		}
	}
	.sc-links {
		display: inline-block;
		color: $white;
		@include font-size(14px);
	}
	.uk-navbar {
		padding: 0 24px;
		border: none;
		border-radius: 0;
		height: $header-height;
		box-sizing: border-box;
		background: $theme-color-dark;
		.uk-navbar-nav {
			> li {
				> a {
					display: inline-block;
					text-align: center;
					margin: 0;
					padding: 0 12px;
					text-shadow: none;
					border: none;
					background: transparent;
					color: $white;
					height: $header-height;
					min-height: $header-height;
					cursor: pointer;
					@include font-size(14px);
					line-height: $header-height;
					> img {
						border-radius: 50%;
					}
					> span {
						position: relative;
					}
					> i,
					> span {
						color: $white;
						&,
						&:before {
							@include font-size(24px);
						}
					}
					.sc-indicator {
						position: absolute;
						right: 4px;
						top: 8px;
						transform: translateX(50%);
						background: material-color('red','600');
						width: 10px;
						height: 10px;
						border-radius: 50%;
						display: block;
						border: 2px solid $theme-color-dark;
					}
				}
				.uk-dropdown-nav {
					padding: 5px 0;
					.uk-nav {
						margin: 0;
					}
				}
				.uk-navbar-dropdown {
					&-bottom-left {
						&:after {
							left: 20px;
						}
					}
					&-bottom-right {
						&:after {
							right: 20px;
						}
					}
				}
				&:last-child {
					> a {
						padding-right: 0;
					}
					.uk-navbar-dropdown {
						&-bottom-right {
							&:after {
								right: 12px;
							}
						}
					}
				}
			}
			.sc-navbar-heading {
				padding: 8px 16px;
				margin-bottom: 8px;
			}
		}
		// tour plugin
		.driver-highlighted-element {
			&,
			a {
				background: $theme-color-dark !important;
			}
		}
		&-aside {
			width: $sidebar-main-width - $spacing-default;
			@include respond-below('medium') {
				width: auto;
			}
		}
		&-left {
			.uk-navbar-nav > li {
				.uk-navbar-dropdown {
					&-bottom-left {
						&:after {
							left: 10px;
						}
					}
				}
			}
		}
	}
	.uk-navbar-brand {
		margin: 0;
		padding: 0;
		height: $header-height;
		line-height: $header-height - 4;
	}
	.uk-search-input {
		@include font-size(14px);
		@include respond-below(small) {
			@include font-size(16px);
		}
		padding: 2px 12px;
		background: $white;
		height: 32px;
		border-radius: 4px;
		border: none;
		box-shadow: none;
	}
	&.uk-active {
		@include elevation(4);
	}
	// tour plugin
	&.driver-fix-stacking {
		@include respond-above('large') {
			position: relative !important;
		}
	}
	@include respond-below('large') {
		&.uk-sticky {
			position: relative;
		}
	}
	&.driver-fix-stacking {
		~ #sc-sidebar-main {
			top: $header-height;
			padding-top: 0;
		}
	}
}
.sc-header {
	&-expanded {
		#sc-header {
			@include elevation(0);
		}
		#sc-page-wrapper {
			position: relative;
			padding-top: $header-height;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: $header-height;
				background: $theme-color-dark;
			}
		}
		@include respond-above(medium) {
			#sc-sidebar-main {
				top: $header-height * 2;
				padding-top: 0;
			}
		}
	}
}

#sc-sidebar-main-toggle {
	color: $white;
	text-decoration: none;
	display: inline-block;
	position: relative;
	margin-right: 16px;
	.sc-menu-close,
	.sc-menu-open {
		color: $white;
		&:before {
			@include font-size(24px);
		}
	}
	@include respond-above(medium) {
		.sc-sidebar-mini & {
			display: none;
		}
	}
	@include respond-above(medium) {
		.sc-menu-close {
			display: block;
			.sc-sidebar-main-slide & {
				display: none;
			}
		}
		.sc-menu-open {
			display: none;
			.sc-sidebar-main-slide & {
				display: block;
			}
		}
	}
	@include respond-below(medium) {
		.sc-menu-close {
			display: none;
			.sc-sidebar-main-visible & {
				display: block;
			}
		}
		.sc-menu-open {
			display: block;
			.sc-sidebar-main-visible & {
				display: none;
			}
		}
	}
	+ .sc-brand {
		max-width: 204px;
	}
}
